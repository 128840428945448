export const vamps = '0x6457AA01f871754e6907B42CBCaF09383eFf60aC'
export const vials = '0xffDD4d26C91752d4f7AEfa4d50737212126c04A2'
export const dai = '0x6B175474E89094C44Da98b954EedeAC495271d0F'
export const weth = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
export const vampFactory = '0x9e7AbF31E9172144E02f501f272a03807aB148b4'
export const vialFactory = '0xcf17a4d78D2236d9Ed7d1A785D0E3CDFf12B109F'
export const daiFactory = '0xA478c2975Ab1Ea89e8196811F51A7B7Ade33eB11'

export const vialUniLp = '0xcf17a4d78D2236d9Ed7d1A785D0E3CDFf12B109F' 
export const vampUniLp = '0x9e7AbF31E9172144E02f501f272a03807aB148b4' 
